export const CLOCKEVENTUNITS = ['year', 'month', 'day', 'timer']
export const TIMEUNITS = ['mins', 'hours', 'days', 'weeks', 'months', 'years']
export const CONVERSIONS = {
    [TIMEUNITS[0]]: (amount) => {return amount * 60000},
    [TIMEUNITS[1]]: (amount) => {return amount * 3600000},
    [TIMEUNITS[2]]: (amount, daysInHours) => {return amount * (3600000 * daysInHours)},
    [TIMEUNITS[3]]: (amount, daysInHours) => {return amount * ((3600000 * daysInHours) * 7)},
    [TIMEUNITS[4]]: (amount, daysInHours, daysInMonths, currentMonth) => {
        if (amount < 0) {
            let returnValue = 0
            let j = currentMonth
            for (let i = amount; i !== 0; i++) {
                returnValue -= ((3600000 * daysInHours) * daysInMonths[j])
                j = j - 1
                if (j === -1) {
                    j = daysInMonths.length - 1
                }
            }
            return returnValue
        } else {
            let returnValue = 0
            let j = currentMonth
            for (let i = amount; i !== 0; i--) {
                returnValue += ((3600000 * daysInHours) * daysInMonths[j])
                j = j + 1
                if (j === daysInMonths.length) {
                    j = 0
                }
            }
            return returnValue
        }
    },
    [TIMEUNITS[5]]: (amount, daysInHours, daysInMonths) => {
        const daysInYear = daysInMonths.reduce((partialSum, a) => partialSum + a, 0)
        return amount * ((3600000 * daysInHours) * daysInYear)
    },
}

export const RELATIONINDICATORCLASSES = {
    "-100": "mx-0pr",
    "-99": "mx-2.5pr",
    "-98": "mx-2.5pr",
    "-97": "mx-2.5pr",
    "-96": "mx-2.5pr",
    "-95": "mx-2.5pr",
    "-94": "mx-5pr",
    "-93": "mx-5pr",
    "-92": "mx-5pr",
    "-91": "mx-5pr",
    "-90": "mx-5pr",
    "-89": "mx-7.5pr",
    "-88": "mx-7.5pr",
    "-87": "mx-7.5pr",
    "-86": "mx-7.5pr",
    "-85": "mx-7.5pr",
    "-84": "mx-10pr",
    "-83": "mx-10pr",
    "-82": "mx-10pr",
    "-81": "mx-10pr",
    "-80": "mx-10pr",
    "-79": "mx-12.5pr",
    "-78": "mx-12.5pr",
    "-77": "mx-12.5pr",
    "-76": "mx-12.5pr",
    "-75": "mx-12.5pr",
    "-74": "mx-15pr",
    "-73": "mx-15pr",
    "-72": "mx-15pr",
    "-71": "mx-15pr",
    "-70": "mx-15pr",
    "-69": "mx-17.5pr",
    "-68": "mx-17.5pr",
    "-67": "mx-17.5pr",
    "-66": "mx-17.5pr",
    "-65": "mx-17.5pr",
    "-64": "mx-20pr",
    "-63": "mx-20pr",
    "-62": "mx-20pr",
    "-61": "mx-20pr",
    "-60": "mx-20pr",
    "-59": "mx-22.5pr",
    "-58": "mx-22.5pr",
    "-57": "mx-22.5pr",
    "-56": "mx-22.5pr",
    "-55": "mx-22.5pr",
    "-54": "mx-25pr",
    "-53": "mx-25pr",
    "-52": "mx-25pr",
    "-51": "mx-25pr",
    "-50": "mx-25pr",
    "-49": "mx-27.5pr",
    "-48": "mx-27.5pr",
    "-47": "mx-27.5pr",
    "-46": "mx-27.5pr",
    "-45": "mx-27.5pr",
    "-44": "mx-30pr",
    "-43": "mx-30pr",
    "-42": "mx-30pr",
    "-41": "mx-30pr",
    "-40": "mx-30pr",
    "-39": "mx-32.5pr",
    "-38": "mx-32.5pr",
    "-37": "mx-32.5pr",
    "-36": "mx-32.5pr",
    "-35": "mx-32.5pr",
    "-34": "mx-35pr",
    "-33": "mx-35pr",
    "-32": "mx-35pr",
    "-31": "mx-35pr",
    "-30": "mx-35pr",
    "-29": "mx-37.5pr",
    "-28": "mx-37.5pr",
    "-27": "mx-37.5pr",
    "-26": "mx-37.5pr",
    "-25": "mx-37.5pr",
    "-24": "mx-40pr",
    "-23": "mx-40pr",
    "-22": "mx-40pr",
    "-21": "mx-40pr",
    "-20": "mx-40pr",
    "-19": "mx-42.5pr",
    "-18": "mx-42.5pr",
    "-17": "mx-42.5pr",
    "-16": "mx-42.5pr",
    "-15": "mx-42.5pr",
    "-14": "mx-45pr",
    "-13": "mx-45pr",
    "-12": "mx-45pr",
    "-11": "mx-45pr",
    "-10": "mx-45pr",
    "-9": "mx-47.5pr",
    "-8": "mx-47.5pr",
    "-7": "mx-47.5pr",
    "-6": "mx-47.5pr",
    "-5": "mx-47.5pr",
    "-4": "mx-50pr",
    "-3": "mx-50pr",
    "-2": "mx-50pr",
    "-1": "mx-50pr",
    "0": "mx-50pr",
    "1": "mx-52.5pr",
    "2": "mx-52.5pr",
    "3": "mx-52.5pr",
    "4": "mx-52.5pr",
    "5": "mx-52.5pr",
    "6": "mx-55pr",
    "7": "mx-55pr",
    "8": "mx-55pr",
    "9": "mx-55pr",
    "10": "mx-55pr",
    "11": "mx-57.5pr",
    "12": "mx-57.5pr",
    "13": "mx-57.5pr",
    "14": "mx-57.5pr",
    "15": "mx-57.5pr",
    "16": "mx-60pr",
    "17": "mx-60pr",
    "18": "mx-60pr",
    "19": "mx-60pr",
    "20": "mx-60pr",
    "21": "mx-62.5pr",
    "22": "mx-62.5pr",
    "23": "mx-62.5pr",
    "24": "mx-62.5pr",
    "25": "mx-62.5pr",
    "26": "mx-65pr",
    "27": "mx-65pr",
    "28": "mx-65pr",
    "29": "mx-65pr",
    "30": "mx-65pr",
    "31": "mx-67.5pr",
    "32": "mx-67.5pr",
    "33": "mx-67.5pr",
    "34": "mx-67.5pr",
    "35": "mx-67.5pr",
    "36": "mx-70pr",
    "37": "mx-70pr",
    "38": "mx-70pr",
    "39": "mx-70pr",
    "40": "mx-70pr",
    "41": "mx-72.5pr",
    "42": "mx-72.5pr",
    "43": "mx-72.5pr",
    "44": "mx-72.5pr",
    "45": "mx-72.5pr",
    "46": "mx-48.25pr",
    "47": "mx-48.25pr",
    "48": "mx-48.25pr",
    "49": "mx-48.25pr",
    "50": "mx-75pr",
    "51": "mx-77.5pr",
    "52": "mx-77.5pr",
    "53": "mx-77.5pr",
    "54": "mx-77.5pr",
    "55": "mx-77.5pr",
    "56": "mx-80pr",
    "57": "mx-80pr",
    "58": "mx-80pr",
    "59": "mx-80pr",
    "60": "mx-80pr",
    "61": "mx-82.5pr",
    "62": "mx-82.5pr",
    "63": "mx-82.5pr",
    "64": "mx-82.5pr",
    "65": "mx-82.5pr",
    "66": "mx-85pr",
    "67": "mx-85pr",
    "68": "mx-85pr",
    "69": "mx-85pr",
    "70": "mx-85pr",
    "71": "mx-87.5pr",
    "72": "mx-87.5pr",
    "73": "mx-87.5pr",
    "74": "mx-87.5pr",
    "75": "mx-87.5pr",
    "76": "mx-90pr",
    "77": "mx-90pr",
    "78": "mx-90pr",
    "79": "mx-90pr",
    "80": "mx-90pr",
    "81": "mx-92.5pr",
    "82": "mx-92.5pr",
    "83": "mx-92.5pr",
    "84": "mx-92.5pr",
    "85": "mx-92.5pr",
    "86": "mx-95pr",
    "87": "mx-95pr",
    "88": "mx-95pr",
    "89": "mx-95pr",
    "90": "mx-95pr",
    "91": "mx-97.5pr",
    "92": "mx-97.5pr",
    "93": "mx-97.5pr",
    "94": "mx-97.5pr",
    "95": "mx-97.5pr",
    "96": "mx-98.25pr",
    "97": "mx-98.25pr",
    "98": "mx-98.25pr",
    "99": "mx-98.25pr",
    "100": "mx-98.5pr",
}